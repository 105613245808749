<script>
  // FANNA SHANNA PACK PAGE
  import FannaPack from "../components/FannaPack.svelte";
  import Paypal from "../components/Paypal.svelte";
</script>

<style>
  h1 {
    text-align: center;
    margin: 0 20px;
    line-height: 1.2;
    max-width: 700px;
  }

  h4 {
    font-size: 16px;
  }

  span.strike {
    text-decoration: line-through;
  }

  .hstyle1 {
    font-family: 'Londrina Solid', sans-serif;
    font-size: 60px;
  }

  .hstyle2 {
    font-family: 'Oswald', sans-serif;
    color: var(--highlight_color);
    font-size: 72px;
  }

  @media (max-width: 760px) {
    .hstyle1 {
      font-size: 48px;
    }

    .hstyle2 {
      font-size: 44px;
    }
  }

  @media (max-width: 413px) {
    .hstyle1 {
      font-size: 48px;
    }

    .hstyle2 {
      font-size: 36px;
    }
  }
</style>

<main>
  <h1>
    <span class="hstyle1">Get a Signed</span><br />
    <span class="hstyle2">Fanna Shanna Pack</span><br />
    <span class="hstyle1">For FREE!</span>
  </h1>
  <h4>
    <span class="strike">$20 Value</span> FREE! (just cover shipping so I don't have to start selling all my dresses)
  </h4>
  <FannaPack />
  <Paypal />
</main>