<script>
  import Logo from "./Logo.svelte"
</script>

<style>
  /* AVAILABLE FONTS: */
  /* font-family: 'Londrina Solid', sans-serif; */
  /* font-family: 'Baloo 2', sans-serif; */
  /* font-family: 'Arima Madurai', cursive; */
  /* font-family: 'Oswald', sans-serif; */
  .fanna_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 100%;
    background-color: var(--bg_color);
    padding: 30px 40px;
    margin: 20px;
    border:1px solid var(--highlight_color);
    border-radius: 30px;
  }

  h3,
  h4 {
    text-align: center;
  }

  .hstyle1 {
    font-size: 36px;
    font-family: 'Londrina Solid', sans-serif;
  }

  .hstyle2 {
    font-size: 60px;
    font-family: 'Oswald', sans-serif;
    color: var(--highlight_color);
  }

  @media (max-width: 760px) {
    .hstyle1 {
      font-size: 32px;
    }

    .hstyle2 {
      font-size: 40px;
    }

    .fanna_box {
      max-width: 400px;
    }
  }
  @media (max-width: 413px) {
    .hstyle1 {
      font-size: 24px;
    }

    .hstyle2 {
      font-size: 30px;
    }

    .fanna_box {
      max-width: 360px;
    }
  }

  .pack-contents {
    display: flex;
    flex-direction: column;
  }

  .line {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .check {
    mask: no-repeat center / contain url(/images/svg/check.svg);
    -webkit-mask: no-repeat center / contain url(/images/svg/check.svg);
    min-width: 30px;
    min-height: 30px;
    background-color: var(--highlight_color);
    margin-right: 20px;
  }

  .item {
    font-size: 20px;
  }
</style>

<div class="fanna_box">
  <h4 class="hstyle1">Here's What You'll Get...</h4>
  <h3 class="hstyle2">Fanna Shanna Pack</h3>
  <Logo size="280px" position="center" />
  <div class="pack-contents">
    <div class="line">
      <div class="check"></div>
      <div class="item">"Love will come back if you give it away" Refrigerator Magnet</div>
    </div>
    <div class="line">
      <div class="check"></div>
      <div class="item">Shanna in a Dress Sticker</div>
    </div>
    <div class="line">
      <div class="check"></div>
      <div class="item">SIGNED Photo</div>
    </div>
    <div class="line">
      <div class="check"></div>
      <div class="item">Shanna in a Dress Air Freshener</div>
    </div>
    <div class="line">
      <div class="check"></div>
      <div class="item">Digital access to Shanna's FIRST EP, "More Than Just a Hoar"</div>
    </div>
    <div class="line">
      <div class="check"></div>
      <div class="item">FannaShanna Temporary Tattoo</div>
    </div>

  </div>
</div>