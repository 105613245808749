<script>
  // PAYMENT SUCCESS PAGE
  import FannaPack from "../components/FannaPack.svelte";
  // import Header from "../components/Header.svelte"
  // import Footer from "../components/Footer.svelte"
</script>
<style>
  /* AVAILABLE FONTS: */
  /* font-family: 'Londrina Solid', sans-serif; */
  /* font-family: 'Baloo 2', sans-serif; */
  /* font-family: 'Arima Madurai', cursive; */
  /* font-family: 'Oswald', sans-serif; */

  h1 {
    text-align: center;
    margin: 0 20px;
    line-height: 1.2;
    max-width: 700px;
  }

  a {
    text-decoration: unset !important;
    background-color: unset !important;
  }

  .hstyle1 {
    font-family: 'Londrina Solid', sans-serif;
    font-size: 48px;
    margin: 5px 0;
  }

  .hstyle1.last {
    margin: 5px 0 0 0;
  }

  .hstyle2 {
    font-size: 48px;
    font-family: 'Oswald', sans-serif;
    color: var(--highlight_color);
    margin: 5px 0;
  }

  .hstyle3 {
    font-family: 'Londrina Solid', sans-serif;
    font-size: 48px;
    border: 3px dashed var(--highlight_color);
    margin: 5px 0;
    padding: 5px 25px;
    display: inline-block;
    border-radius: 30px;
  }

  a:hover .hstyle3 {
    background-color: var(--highlight_color);
  }

  @media (max-width: 760px) {

    .hstyle1 {
      font-size: 32px;
    }

    .hstyle2 {
      font-size: 32px;
    }

    .hstyle3 {
      font-size: 32px;
    }
  }

  @media (max-width: 413px) {
    .hstyle1 {
      font-size: 28px;
    }

    .hstyle2 {
      font-size: 26px;
    }

    .hstyle3 {
      font-size: 20px;
    }
  }
</style>

<main>
  <h1>
    <div class="hstyle1">Your Fanna Shanna Pack order was Successful!</div>
    <div class="hstyle2">Thank You for your Support!</div>
    <div class="hstyle1">Now...</div>
    <a href="/music/more_than_just_a_hoar.zip">
      <div class="hstyle3">Download Shanna's First EP,<br /><em>More than just a Hoar</em></div>
    </a>
    <div class="hstyle1 last">The rest will be sent<br />within 2-6 weeks</div>
  </h1>

  <FannaPack />
</main>